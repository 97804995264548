<template>
  <v-select
    v-model="selectedItems"
    :items="items"
    :item-text="itemText"
    :item-value="itemId"
    :label="label"
    @change="onChange"
    multiple
    chips
    :value="selectedItems"
    :rules="rules"
  >
    <v-list-item slot="prepend-item" ripple @click="toggle">
      <v-list-item-action>
        <v-icon :color="selectedItems.length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-title>
        <div v-if="!allSelected">Select All</div>
        <div v-else>Unselect All</div>
      </v-list-item-title>
    </v-list-item>
    <v-divider slot="prepend-item" class="mt-2"></v-divider>
    <v-divider slot="append-item" class="mb-2"></v-divider>
  </v-select>
</template>

<script>
export default {
  name: "MultiSelect",
  props: {
    syncField: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    },
    rules: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ""
    },
    itemText: {
      type: String,
      default: "name"
    },
    itemId: {
      type: String,
      default: "text"
    }
  },
  data: () => ({
    selectedItems: []
  }),

  computed: {
    allSelected() {
      return this.selectedItems.length === this.$props.items.length;
    },
    someSelected() {
      return this.selectedItems.length > 0 && !this.allSelected;
    },
    icon() {
      if (this.allSelected) return "mdi-close-box";
      if (this.someSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    handleValueChange(val) {
      if (this.$props.syncFields) {
        this.$emit("update:field", val);
      }
      this.$emit("change", val);
      return true;
    }
  },

  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.selectedItems = [];
        } else {
          this.selectedItems = this.$props.items.slice();
        }

        let selected = [];
        this.selectedItems.forEach(si => {
          selected.push(si[this.$props.itemId]);
        });
        this.onChange(selected);
      });
    },
    onChange: function(event) {
      if (this.$props.syncField) {
        this.$emit("update:field", event);
      } else {
        this.$emit("change", event);
      }
    },
  }
};
</script>