<template>
  <v-card>
    <v-toolbar card color="primary" dark>
      <v-toolbar-title v-show="!quoteAvailable && customerDetails == false"
        >Get Project Quote</v-toolbar-title
      >
      <v-toolbar-title v-show="quoteAvailable">Total Cost</v-toolbar-title>
      <v-toolbar-title v-show="customerDetails">Details</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <div v-show="!quoteAvailable && !customerDetails">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-select
            :items="data.countries.options"
            label="Country"
            v-model="project.country"
            item-text="name"
            item-value="name"
            v-validate="'required'"
            required
            multiple
            data-vv-name="country"
            :error-messages="errors.collect('country')"
          />
          <v-textarea
            name="description"
            label="Project Description"
            auto-grow
            v-model="project.description"
            v-validate="'required'"
            required
            data-vv-name="description"
            :error-messages="errors.collect('description')"
          ></v-textarea>

          <!-- For Desktops -->
          <v-select
            class="hidden-sm-and-down"
            v-model="project.product"
            :items="data.products.options"
            label="Product"
            item-text="name"
            item-value="value"
            chips
            clearable
            v-validate="'required'"
            required
            data-vv-name="product"
            :error-messages="errors.collect('product')"
          >
            <template slot="item" slot-scope="data">
              <template>
                <v-list-tile-content>
                  <v-list-tile-title
                    v-html="data.item.name"
                  ></v-list-tile-title>
                  <v-list-tile-sub-title
                    v-html="data.item.group"
                  ></v-list-tile-sub-title>
                </v-list-tile-content>
              </template>
            </template>
          </v-select>

          <!-- For Mobile Devices -->

          <v-select
            class="hidden-lg-and-up hidden-md-and-up"
            v-model="project.product"
            :items="data.products.options"
            label="Product"
            item-text="name"
            item-value="value"
            chips
            clearable
            v-validate="'required'"
            required
            data-vv-name="product"
            :error-messages="errors.collect('product')"
          >
          </v-select>

          <v-select
            v-model="project.category"
            :items="data.categories"
            label="Category"
            item-text="name"
            item-value="name"
            chips
            v-validate="'required'"
            required
            data-vv-name="category"
            :error-messages="errors.collect('category')"
          ></v-select>

          <v-select
            v-model="project.lengthOfInterview"
            :items="data.interviewLengthOptions.options"
            label="Length Of Interview"
            item-text="name"
            item-value="name"
            chips
            v-validate="'required'"
            clearable
            required
            data-vv-name="Length Of Interview"
            :error-messages="errors.collect('Length Of Interview')"
          ></v-select>

          <v-select
            v-model="project.incidenceRate"
            :items="data.incidenceOptions.options"
            label="Estimate IR"
            item-text="name"
            item-value="name"
            chips
            clearable
            v-validate="'required'"
            required
            data-vv-name="ir"
            :error-messages="errors.collect('ir')"
          ></v-select>

          <v-select
            label="Sample Size"
            :items="samples"
            v-model="project.sampleSize"
            v-validate="'required|numeric'"
            required
            data-vv-name="sample size"
            :error-messages="errors.collect('sample size')"
            item-text="text"
            item-value="value"
            chips
            clearable
          ></v-select>

          <MultiSelect
            name="age groups"
            :items="data.ageGroupOptions.options"
            label="Age Group"
            :syncField="true"
            :field.sync="project.ageGroup"
            validations="required"
          />

          <v-select
            :items="data.outputOptions.options"
            label="Output Requirements"
            v-model="project.outputRequirements"
            item-text="name"
            item-value="value"
            chips
            clearable
            v-validate="'required'"
            required
            data-vv-name="output requirements"
            :error-messages="errors.collect('output requirements')"
          />
        </v-form>
        <v-btn
          large
          slot="activator"
          color="blue"
          dark
          @click.prevent="getQuote()"
          >GET QUOTE</v-btn
        >
      </div>

      <!-- Quotation Section -->
      <div v-show="quoteAvailable">
        <!-- <div> -->
        <h2 v-if="undefined != quote.grandTotal">
          Your total cost is : USD {{ quote.grandTotal.toLocaleString() }}
        </h2>
        <br />
        <br />

        <v-data-table
          v-if="loaded == true && quote.countries.length > 1"
          :headers="headers"
          :items="quote.countries"
          :items-per-page="5"
          class="elevation-1 mb-10"
        ></v-data-table>

        <v-container fluid v-if="loaded == true && quote.countries.length == 1">
          <v-row>
            <v-col cols="12" md="6" lg="3">
              <v-card color="#264653" dark height="150">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-card-title class="font-weight-thin ml-3 sm-4">
                    Country
                    <h1
                      class="font-weight-light ml-3 hidden-sm-and-down costText"
                    >
                      {{ quote.countries[0].country }}
                    </h1>
                    <h3
                      class="font-weight-light ml-3 hidden-lg-and-up hidden-md-and-up"
                    >
                      {{ quote.countries[0].country }}
                    </h3>
                  </v-card-title>
                  <v-avatar size="100" tile>
                    <v-icon x-large>map</v-icon>
                  </v-avatar>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" lg="3">
              <v-card color="#2A9D8F" dark height="150">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-card-title class="font-weight-thin ml-3">
                    Product
                    <h1
                      class="font-weight-light ml-3 hidden-sm-and-down costText"
                    >
                      {{ quote.countries[0].product }}
                    </h1>
                    <h3
                      class="font-weight-light ml-3 hidden-lg-and-up hidden-md-and-up"
                    >
                      {{ quote.countries[0].product }}
                    </h3>
                  </v-card-title>
                  <v-avatar size="100" tile>
                    <v-icon x-large>inbox</v-icon>
                  </v-avatar>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" lg="3">
              <v-card color="#6d597a" dark height="150">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-card-title class="font-weight-thin ml-3">
                    Cost
                    <h1
                      class="font-weight-light ml-3 hidden-sm-and-down costText"
                    >
                      {{
                        `${quote.countries[0].currency_code}  ${Math.round(
                          quote.countries[0].localCurrency
                        )}`
                      }}
                    </h1>
                    <h4
                      class="font-weight-light ml-3 hidden-lg-and-up hidden-md-and-up"
                    >
                      {{
                        `${quote.countries[0].currency_code}  ${Math.round(
                          quote.countries[0].localCurrency
                        )}`
                      }}
                    </h4>
                  </v-card-title>
                  <v-avatar size="100" tile>
                    <v-icon x-large>attach_money</v-icon>
                  </v-avatar>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" lg="3">
              <v-card color="#AF5B5B" dark height="150">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-card-title class="font-weight-thin ml-3">
                    Cost
                    <h1
                      class="font-weight-light ml-3 hidden-sm-and-down costText"
                    >
                      {{ `USD  ${quote.countries[0].usdCurrency}` }}
                    </h1>
                    <h4
                      class="font-weight-light ml-3 hidden-lg-and-up hidden-md-and-up"
                    >
                      {{ `USD  ${quote.countries[0].usdCurrency}` }}
                    </h4>
                  </v-card-title>
                  <v-avatar size="100" tile>
                    <v-icon x-large>attach_money</v-icon>
                  </v-avatar>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-btn
          large
          slot="activator"
          color="blue"
          dark
          @click.prevent="goToPage1()"
          >BACK</v-btn
        >
        <v-btn
          large
          slot="activator"
          color="blue"
          dark
          class="ml-5"
          @click.prevent="goToPage2()"
          >Next</v-btn
        >
      </div>

      <!-- Customer details form -->

      <div v-show="customerDetails">
        <v-container fluid>
          <v-form ref="form2" lazy-validation>
            <v-row>
              <v-col cols="12" md="6" lg="6">
                <v-text-field
                  v-model="customerInfo.company"
                  label="Company"
                  :rules="[(v) => !!v || 'Company is required']"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="6">
                <v-text-field
                  v-model="customerInfo.name"
                  label="Name"
                  :rules="[(v) => !!v || 'Name is required']"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="6">
                <v-text-field
                  v-model="customerInfo.email"
                  label="Email"
                  :rules="emailRules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="6">
                <v-text-field
                  v-model="customerInfo.phoneNumber"
                  :rules="[(v) => !!v || 'Phone Number is required']"
                  label="Phone Number"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6" lg="6">
                <v-file-input
                  show-size
                  v-model="file"
                  label="File input"
                  persistent-hint
                  hint="Upload project information/questionarrie file"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
          <!-- <div  align="center" class="py-4">
              <v-btn color="black">Upload</v-btn>
            </div> -->

          <v-btn
            class="mr-5"
            slot="activator"
            color="blue"
            dark
            @click.prevent="goToHome()"
            >BACK</v-btn
          >
          <v-btn dark color="blue" @click="submit()">Submit</v-btn>
        </v-container>
        <div align="center" class="py-4"></div>
      </div>
      <div>
        <v-dialog
        v-model="dialog"
        width="500"
        persistent
        >
         <v-card>
        <v-card-title class="headline grey lighten-2">
         Message
        </v-card-title>

        <v-card-text class="mt-5">
         Your details has been successfully submitted. Our team will contact you soon.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="redirect()"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
        </v-dialog>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { DataService } from "../services/BuyerDataService";
import MultiSelect from "../components/MultiSelect";

const dataService = new DataService();

export default {
  components: {
    MultiSelect,
  },
  data() {
    return {
      headers: [
        { text: "Country", value: "country", sortable: false },
        {
          text: "Cost (Local Currency)",
          value: "localCurrency",
          sortable: false,
        },
        { text: "Cost (USD)", value: "usdCurrency", sortable: false },
      ],
      samples: ["100", "150", "200", "250", "300", "400", "500", "1000"],
      valid: true,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      value: [],
      file: [],
      disableButton: false,
      quote: [],
      quoteAvailable: false,
      customerDetails: false,
      projectFeasible: true,
      project: {},
      projectCategoryOptions: [],
      productOptions: [],
      ageGroups: [],
      loaded: false,
      customerInfo: {},
      choosenFile: null,
      details: true,
      data: [],
      dialog: false
    };
  },

  methods: {
    async getQuote() {
      let v = await this.$validator.validateAll();
      if (v && this.$validator.errors.items.length == 0) {
        this.$setLoader();
        dataService
          .getQuote(this.project)
          .then((r) => {
            this.quoteAvailable = true;
            this.quote = r.cost;
            this.loaded = true;
            this.$clearLoader();
          })
          .catch((error) => {
            alert(error);
            this.$clearLoader();
          });
      }
    },

    goToHome() {
      (this.customerDetails = false), (this.quoteAvailable = false);
    },

    goToPage1() {
      this.quoteAvailable = false;
    },
    goToPage2() {
      this.customerDetails = true;
      this.quoteAvailable = false;
      this.toolbarTitle = "Details";
    },
    async submit() {
      
       let v = await this.$refs.form2.validate();

       if(v) {
         let formy = new FormData();
   
         formy.append("file", this.file ? this.file : "No file");
         formy.append("customerInfo", JSON.stringify(this.customerInfo));
         formy.append("quotation", JSON.stringify(this.quote));
          this.$setLoader()
         dataService.saveQuotation(formy).then((res) => {
           console.log(res);
           this.$clearLoader()
           this.dialog= true
         });
       }

    },

    async loadSpecs() {
      this.$setLoader();
      dataService
        .getCreateProjectConfigs()
        .then((r) => {
          this.data = r.configs;
          this.$clearLoader();
        })
        .catch((err) => {
          alert(err);
        });
    },
    redirect() {
      window.location.reload()
    }
   },
  mounted() {
    this.loadSpecs();
  },
};
</script>

<style scoped>
v-list-tile-sub-title:before {
  content: "\00a0 ";
}

v-list-tile-sub-title {
  color: #525252;
  font-size: 15px;
}

/* @media only screen and (max-width: 600px) {
 .costText {
    color: red;
  }
} */

@media (min-width: 1264px) and (max-width: 1776px) {
  .costText {
    font-size: 23px;
    font-weight: bold;
  }
}
</style>
